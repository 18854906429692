import React, { Component } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

export default class Privacy extends Component {
  render() {
    const { data } = this.props;
    return (
      <Layout>
        <SEO
          title="Cookies Policy | Miquel Trallero | Fullstack Blockchain Developer"
        />
        <div className="site-container ">
          <div className="container">        
            <h1>COOKIES POLICY</h1>

            <p>This website uses third-party and own cookies to get you to have a better browsing experience, you can share content on social networks and so that we can obtain user statistics.</p>
            <p>You can prevent the downloading of cookies through your browser settings, preventing cookies from being stored on your device.</p>
            <p>As the owner of this website, I inform you that we do not use any personal information from cookies, we only make general statistics of visits that do not involve any personal information.</p>
            <p>It is very important that you read this cookie policy and understand that if you continue browsing, we will consider that you accept its use.</p>
            <p>According to the terms included in Article 22.2 of Law 34/2002 of Services of the Information Society and Electronic Commerce, if you continue browsing, you will be giving your consent to the use of the aforementioned mechanisms.</p>

            <h2>Responsible Entity</h2>

            <p>The entity responsible for the collection, processing and use of your personal data, in the sense established by the Personal Data Protection Act is the Miquel Trallero website, owned by Miquel Trallero Campins - Travessera De Les Corts, 194, Barcelona, Spain.</p>

            <h2>What are cookies?</h2>

            <p>Cookies are a set of data that a server deposits in the user's browser to collect standard Internet log information and visitor behavior information on a website. In other words, they are small text files that are stored on the computer's hard drive and are used to identify the user when he/she connects to the website again. Their purpose is to record the user's visit and store certain information. Its use is common and frequent on the web as it allows pages to function more efficiently and achieve greater customization and analysis of user behavior.</p>
            
            <h2>What types of cookies exist?</h2>

            <p>The cookies used on our website, are session and third party, and allow us to store and access information about the language, type of browser used, and other general characteristics predefined by the user, as well as track and analyze the activity carried out, in order to make improvements and provide our services in a more efficient and personalized way.</p>
            <p>Cookies, depending on their permanence, can be divided into session or permanent cookies. Those that expire when the user closes the browser. Those that expire depending on when the purpose for which they serve is fulfilled (for example, for the user to remain identified in the services of Miquel Trallero Campins) or when they are manually deleted.</p>
            <p>Cookies can be classified as follows:</p>

            <h3>Performance Cookies</h3>

            <p>This type of Cookie remembers your preferences for the tools found in the services, so you do not have to reconfigure the service each time you visit. By way of example, this typology includes: Volume settings for video or sound players. Video streaming speeds that are compatible with your browser. Objects saved in the "shopping cart" in e-commerce services such as stores.</p>

            <h3>Geo-location cookies</h3>

            <p>These cookies are used to find out which country you are in when you request a service. This cookie is completely anonymous, and is only used to help target content to your location.</p>

            <h3>Registration cookies</h3>

            <p>Registration cookies are generated once the user has registered or subsequently logged in, and are used to identify you in the services with the following objectives:</p>
            <p>To keep the user identified so that, if you close a service, the browser or the computer and at another time or another day re-enter that service, you will continue to be identified, thus facilitating your navigation without having to identify yourself again. This functionality can be deleted if the user clicks on the [log out] functionality, so that this cookie is deleted and the next time the user enters the service he/she will have to log in to be identified.</p>
            <p>Check if the user is authorized to access certain services, for example, to participate in a contest.</p>
            <p>Additionally, some services may use connectors with social networks such as Facebook or Twitter. When the user registers for a service with credentials from a social network, he authorizes the social network to store a persistent Cookie that remembers his identity and grants him access to the services until it expires. The user can delete this Cookie and revoke access to services through social networks by updating their preferences on the specific social network.</p>
            
            <h3>Analytical Cookies</h3>

            <p>Each time a user visits a service, a tool from an external provider generates an analytics cookie on the user's computer. This cookie, which is only generated during the visit, will be used in future visits to the services of Miquel Trallero Campins to anonymously identify the visitor. The main objectives pursued are:</p>
            <p>Allow anonymous identification of users browsing through the cookie (identifies browsers and devices, not people) and therefore the approximate accounting of the number of visitors and their trend over time.</p>
            <p>Identify anonymously the most visited content and therefore more attractive to users Know if the user who is accessing is new or repeat visit.</p>
            <p>Important: Unless the user decides to register for a Miquel Trallero Campins service, the cookie will never be associated with any personal data that can identify the user. These cookies will only be used for statistical purposes to help optimize the user experience on the site.</p>

            <h3>Advertising cookies</h3>

            <p>This type of cookies allow to expand the information of the advertisements shown to each anonymous user in the services of Miquel Trallero Campins. Among others, they store the duration or frequency of display of advertising positions, interaction with them, or browsing patterns and / or user behaviors as they help to shape a profile of advertising interest. In this way, they make it possible to offer advertising related to the user's interests.</p>

            <h3>Third-party advertising cookies</h3>

            <p>In addition to the advertising managed by the Miquel Trallero Campins websites in their services, the Miquel Trallero Campins websites offer their advertisers the option of serving ads through third parties ("Ad-Servers"). In this way, these third parties can store cookies sent from Miquel Trallero Campins services from users' browsers, as well as access the data stored in them.</p>
            <p>The companies that generate these cookies have their own privacy policies. Currently, Miquel Trallero Campins websites use the Doubleclick platform (Google) to manage these services. For more information, go to</p>
            <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy</a>
            <br/>
            <br/>

            <h2>How can I disable cookies in my browser?</h2>

            <p>Different browsers can be configured to warn the user of the reception of cookies and, if desired, prevent their installation on the computer. Likewise, the user can check in his browser which cookies he has installed and what is the expiration date of the same, being able to delete them.</p>
            <p>For more information, please consult the instructions and manuals of your browser:</p>
            <p>For more information on the management of cookies in Google Chrome:</p>
            <a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" rel="noopener noreferrer">https://support.google.com/chrome/answer/95647?hl=es</a>
            <br/>
            <br/>
            
            <p>For more information on the administration of cookies in Internet Explorer: </p>
            <a href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank" rel="noopener noreferrer">https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d</a>
            <br/>
            <br/>
            
            <p>For more information on how to manage cookies in Mozilla Firefox:</p>
            <a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we" target="_blank" rel="noopener noreferrer">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a>
            <br/>
            <br/>
            
            <p>For more information about managing cookies in Safari:</p>
            <a href="http://www.apple.com/es/privacy/use-of-cookies/" target="_blank" rel="noopener noreferrer">http://www.apple.com/es/privacy/use-of-cookies/</a>
            <br/>
            <br/>
            
            <p>For more information about managing cookies in Opera: </p>
            <a href="http://help.opera.com/Windows/11.50/es-ES/cookies.html" target="_blank" rel="noopener noreferrer">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a>
            <br/>
            <br/>
            
            <p>If you wish to stop being tracked by Google Analytics visit:</p>
            <a href="http://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">http://tools.google.com/dlpage/gaoptout</a>
            <br/>
            <br/>            
            
            <h2>Updates and changes to the privacy/cookie policy</h2>
            <p>The websites of Miquel Trallero Campins may modify this Cookies Policy according to legislative or regulatory requirements, or in order to adapt this policy to the instructions issued by the Spanish Data Protection Agency, so users are advised to visit it periodically.</p>

          </div>
        </div>        
        
      </Layout>
    );
  }
}